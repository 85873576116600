<template>
  <transition name="fade">
    <div v-if="show" class="loader">
      <b-spinner
        variant="primary"
        label="Loading..."
        :style="style"
      />
    </div>
  </transition>
</template>

<script>

const sizes = {
  small: 2,
  default: 4,
  large: 6
}

export default {
  name: 'PageLoader',
  props: {
    show: {
      default: false,
      required: false
    },
    size: {
      type: String,
      default: 'default'
    }
  },
  computed: {
    style() {
      const val = sizes[this.size];
      return {
        width: `${val}rem`,
        height: `${val}rem`,
        borderWidth: `0.${val}em`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .loader {
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 1003;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(#fff, 0.3);
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>