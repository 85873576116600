<template>
  <b-modal
    v-model="isOpen"
    size="md"
    centered
    scrollable
    :hide-footer="dismissalWarning?.status !== 'pending'"
    title="Dismissal Warning"
    dialog-class="chat-modal"
    @hide="clearDismissalWarning"
  >
    <template #modal-title>
      Dismissal Warning
      <template v-if="dismissalWarning">
        - <span class="text-danger">{{ daysLeft }} Days Left</span>
      </template>
    </template>
    <loader :show="loading" />
    <div v-if="dismissalWarning">
      <div
        v-for="(item, index) in dismissalWarning.messages"
        :key="index"
        class="chat-modal__message message"
      >
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <b-avatar
              variant="info"
              size="30px"
              :text="getNameInitials(item.author.name)"
              :src="item.author.photo"
            />
            <span class="message__author">{{ item.author.name }}</span>
          </div>
          <div class="message__date">{{ $moment(item.created_at).format('DD.MM.YYYY HH:mm') }}</div>
        </div>
        <p class="message__text">{{ item.message }}</p>
      </div>
      <div
        v-if="dismissalWarning.status === 'pending'"
        class="chat-modal__form"
      >
        <b-form-group
          class="m-0 required"
          label="Your Reply"
          label-for="chat-input"
        >
          <b-form-textarea
            id="chat-input"
            v-model="message"
            rows="5"
            max-rows="6"
          />
        </b-form-group>
      </div>
    </div>
    <template #modal-footer>
      <div
        class="d-flex align-items-center w-100"
        :class="canMakeActions ? 'justify-content-between' : 'justify-content-end'"
      >
        <div
          v-if="canMakeActions"
          class="d-flex"
        >
          <b-button
            variant="success"
            :disabled="loading || !message"
            @click="sendMessageWithStatus('resolved')"
          >
            Resolve
          </b-button>
          <b-button
            class="ml-2"
            variant="danger"
            :disabled="loading || !message"
            @click="sendMessageWithStatus('dismissed')"
          >
            Dismiss
          </b-button>
        </div>
        <b-button
          :disabled="loading || !message"
          variant="primary"
          @click="sendMessage"
        >
          Reply
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { getNameInitials } from '@/utils/user';
import { mapActions } from 'vuex';
import Loader from '../Loader.vue';
import { WARNING_PERIOD } from '@/utils/constants';

export default {
  components: { Loader },
  props: {
    value: Boolean,
    warningId: Number
  },
  data() {
    return {
      message: '',
      loading: false
    }
  },
  methods: {
    getNameInitials,
    ...mapActions('dismissalWarning', [
      'getDismissalWarning',
      'updateDismissalWarning',
      'clearDismissalWarning',
    ]),
    async sendMessageWithStatus(status) {
      const data = { status };
      if (this.message) {
        data.message = this.message
      }
      await this.handleUpdateWarning(data);
      this.$emit('onStatusChange');
      this.isOpen = false;
    },
    async sendMessage() {
      await this.handleUpdateWarning({ message: this.message });
      this.isOpen = false;
      this.$showSuccessToast('Your reply has been sent');
    },
    async handleUpdateWarning(data) {
      this.loading = true;
      await this.updateDismissalWarning({ id: this.warningId, data });
      await this.getDismissalWarning(this.warningId);
      this.message = '';
      this.loading = false;
    }
  },
  computed: {
    isOpen: {
      set(value) {
        this.$emit('input', value);
      },
      get() {
        return this.value;
      }
    },
    user() {
      return this.$store.state.user.user;
    },
    dismissalWarning() {
      return this.$store.state.dismissalWarning.selected;
    },
    canMakeActions() {
      return this.$hasAccess(['admin', 'hrm']) || this.dismissalWarning?.from_employee_uid === this.user.employee.uid;
    },
    daysLeft() {
      if (!this.dismissalWarning) {
        return 0;
      }
      const diff = WARNING_PERIOD - this.dismissalWarning.days_left;
      return diff > 0 ? diff : 0;
    },
  },
  watch: {
    async isOpen(value) {
      if (value && this.warningId) {
        this.loading = true;
        await this.getDismissalWarning(this.warningId);
        this.loading = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.modal-body) {
  min-height: 100px;
  padding: 0px 20px 20px 20px;
}
:deep(.loader) span {
  width: 40px !important;
  height: 40px !important;
  border-width: 3px !important;
}
.chat-modal {
  &__message {
    margin: 0 -20px;
  }
  &__form {
    margin-top: 20px;
  }
}
.message {
  padding: 20px;
  border-bottom: 1px solid #E3E4E8;

  &__author {
    color: #343A40;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    margin-left: 10px;
  }
  &__date {
    color: #74788D;
    font-size: 13px;
    line-height: 20px;
  }
  &__text {
    margin-top: 10px;
    color: #2A3042;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    white-space: pre-wrap;
  }
}
</style>
